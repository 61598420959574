<template>
  <div id="seo-content" class="seo-txt" v-html="seoHtml" />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useSearchStore } from '@/store/search'
const { locale } = useI18n()
const searchStore = useSearchStore()
const config = useRuntimeConfig()
const seoHtml = ref('')
const emit = defineEmits(['showReadMoreLink'])
if (searchStore.aresKey) {
  // Get SEO content
  const getSeoContent = await $fetch<SeoContent>(`${config.public.ARES_API}/getSeoContent`, {
    method: 'POST',
    body: {
      lang: locale.value,
      key: searchStore.aresKey
    }
  })
  seoHtml.value = getSeoContent.seoTxt ? getSeoContent.seoTxt : ''
  emit('showReadMoreLink', !!seoHtml.value)
}
</script>

<style lang="postcss" scoped>
#seo-content{
  @apply scroll-mt-[250px];
}
.seo-txt{
  @apply mx-2;
  :deep(p){
    @apply my-3;
  }
  :deep(a){
    @apply underline;
  }
  :deep(ul){
    @apply list-image-[url(/img/list-style-img.svg)] ml-3 mb-3;
    li{
      *{
        @apply mb-1;
      }
    }
  }
  :deep(ol){
    @apply list-image-[url(/img/list-style-img.svg)] ml-3 mb-3;
    li{
      *{
        @apply mb-1;
      }
    }
  }
}
</style>
