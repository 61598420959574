<template>
  <div class="internal-links">
    <h2>{{ title }}</h2>
    <div class="links-wrapper">
      <!-- <NuxtLink v-for="(link,i) in internalLinks" :key="i" :to="{ path:link.path, query:link.query}" @click.prevent="handleUrlQuery(link.query)">
        {{ link.text }}
      </NuxtLink> -->
      <a v-for="(link,i) in internalLinks" :key="i" :href="link.path">
        {{ link.text }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useSearchStore } from '@/store/search'
// import { useUrlParamsStore } from '@/store/urlParams'
// const urlParamsStore = useUrlParamsStore()
const { locale, t } = useI18n()
const route = useRoute()
// const config = useRuntimeConfig()
const searchStore = useSearchStore()
const internalLinks = ref<InternalLinks[]>([])
const title = ref('')
// Get countries array
const { countries } = storeToRefs(searchStore)

// const handleUrlQuery = (query:InternalLinksQuery) => {
//   urlParamsStore.set('lastMinutes', query.lastMinutes ? 'true' : '')
// }

// Build internal links
if (!searchStore.searchType) {
  title.value = t('destination.discoverOurOffer')
  const countriesArr = countries.value.map(x => x.country)
  countriesArr.forEach((x) => {
    const countrySlugObj = searchStore.unslugify.find(y => y.unslug === x)
    if (countrySlugObj) {
      let path = ''
      switch (locale.value) {
        case 'nl':
          path = `/nl/vakantiehuis-${countrySlugObj.slug}/`
          break
        case 'de':
          path = `/de/ferienhaus-${countrySlugObj.slug}/`
          break
        case 'fr':
          path = `/fr/maison-de-vacances-${countrySlugObj.slug}/`
          break
        default:
          path = `/en/holiday-home-${countrySlugObj.slug}/`
      }
      const query = {} as InternalLinksQuery
      if (route.path.startsWith('/nl/last-minute') || route.path.startsWith('/en/last-minute') || route.path.startsWith('/de/last-minute') || route.path.startsWith('/fr/derniere-minute')) {
        query.lastMinutes = 'true'
      }
      internalLinks.value.push({
        path,
        query,
        text: countrySlugObj.unslug
      })
    }
  })
}
if (searchStore.searchType === 'country') {
  const countrySlugObj = searchStore.unslugify.find(x => x.unslug === searchStore.searchInput)
  if (countrySlugObj) {
    const country = countries.value.find(x => x.country === countrySlugObj.unslug)
    if (country) {
      title.value = t('destination.regionsIn', { country: countrySlugObj.unslug })
      country.regions.forEach((x) => {
        const regionSlugObj = searchStore.unslugify.find(y => y.unslug === x.region)
        if (regionSlugObj) {
          let path = ''
          switch (locale.value) {
            case 'nl':
              path = `/nl/vakantiehuis-${countrySlugObj.slug}/${regionSlugObj.slug}/`
              break
            case 'de':
              path = `/de/ferienhaus-${countrySlugObj.slug}/${regionSlugObj.slug}/`
              break
            case 'fr':
              path = `/fr/maison-de-vacances-${countrySlugObj.slug}/${regionSlugObj.slug}/`
              break
            default:
              path = `/en/holiday-home-${countrySlugObj.slug}/${regionSlugObj.slug}/`
          }
          const query = {} as InternalLinksQuery
          if (route.path.startsWith('/nl/last-minute') || route.path.startsWith('/en/last-minute') || route.path.startsWith('/de/last-minute') || route.path.startsWith('/fr/derniere-minute')) {
            query.lastMinutes = 'true'
          }
          internalLinks.value.push({
            path,
            query,
            text: regionSlugObj.unslug
          })
        }
      })
    }
  }
}
if (searchStore.searchType === 'region') {
  const regionSlugObj = searchStore.unslugify.find(x => x.unslug === searchStore.searchInput)
  if (regionSlugObj) {
    const countryObj = countries.value.find(x => x.regions.find(y => y.region === regionSlugObj.unslug))
    if (countryObj) {
      const countrySlugObj = searchStore.unslugify.find(y => y.unslug === countryObj.country)
      const regionObj = countryObj.regions.find(x => x.region === regionSlugObj.unslug)
      if (countrySlugObj && regionObj) {
        title.value = t('destination.placenamesIn', { region: regionSlugObj.unslug })
        regionObj.placenames.forEach((x) => {
          const placeSlugObj = searchStore.unslugify.find(y => y.unslug === x.name)
          if (placeSlugObj) {
            const regionProvinceObj = countryObj.regions.find(w => w.regionType === 'province' && w.placenames.find(z => z.name === placeSlugObj.unslug))
            if (regionProvinceObj) {
              const regionProvinceSlugObj = searchStore.unslugify.find(y => y.unslug === regionProvinceObj.region)
              if (regionProvinceSlugObj) {
                let path = ''
                switch (locale.value) {
                  case 'nl':
                    path = `/nl/vakantiehuis-${countrySlugObj.slug}/${regionProvinceSlugObj.slug}/${placeSlugObj.slug}/`
                    break
                  case 'de':
                    path = `/de/ferienhaus-${countrySlugObj.slug}/${regionProvinceSlugObj.slug}/${placeSlugObj.slug}/`
                    break
                  case 'fr':
                    path = `/fr/maison-de-vacances-${countrySlugObj.slug}/${regionProvinceSlugObj.slug}/${placeSlugObj.slug}/`
                    break
                  default:
                    path = `/en/holiday-home-${countrySlugObj.slug}/${regionProvinceSlugObj.slug}/${placeSlugObj.slug}/`
                }
                const query = {} as InternalLinksQuery
                if (route.path.startsWith('/nl/last-minute') || route.path.startsWith('/en/last-minute') || route.path.startsWith('/de/last-minute') || route.path.startsWith('/fr/derniere-minute')) {
                  query.lastMinutes = 'true'
                }
                internalLinks.value.push({
                  path,
                  query,
                  text: placeSlugObj.unslug
                })
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.internal-links{
  @apply m-2;
  h2{
    @apply text-xl font-bold mb-1;
  }
  .links-wrapper{
    @apply grid md:grid-cols-2 gap-x-1;
    a{
      @apply font-bold text-green underline;
    }
  }
}
</style>
